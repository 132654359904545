<template>
  <div class="subsidiary">
      <banner :bannerIndex="5" />
      <div class="public_title public_title animate__animated animate__fadeInDown" v-if="fieldShow">联系我们</div>
      <div class="content_w cont_one" v-if="fieldShow">
        <div class="cont_ct animate__animated animate__fadeInLeftBig ">
          <div class="cont_ct_txt">{{content0.title}}</div>
          <ul class="cont_ul">
            <li class="cont_li">
              <img src="../../static/img/con_tu1.png" class="cont_icon"/>
              <div class="cont_ny">
                <span>联系电话</span>
                {{content0.title2}}
              </div>
            </li>
            <li class="cont_li">
              <img src="../../static/img/con_tu2.png" class="cont_icon"/>
              <div class="cont_ny">
                <span>传真</span>
                {{content0.title3}}
              </div>
            </li>
            <li class="cont_li">
              <img src="../../static/img/con_tu3.png" class="cont_icon"/>
              <div class="cont_ny">
                <span>地址</span>
                {{content0.title4}}
                <div class="cont_ewm">
                  <img :src="content0.pic"/>
                  <span>关注微信公众号</span>
                </div>
              </div>
            </li>
           
          </ul>
        </div>
        <!-- animate__animated animate__fadeInUp -->
        <div class="lxwm_box animate__animated animate__fadeInRight">
          <!-- 百度地图 -->
          <baidu-map
            :center="center"
            :zoom="zoom"
            @ready="handler"
            class="lxwm_dt"
            @click="getClickInfo"
            :scroll-wheel-zoom="true"
          ></baidu-map>
        </div>
      </div>
      <div class="content_w" v-if="fieldShow">
        <ul class="tel_box tel_ul animate__animated animate__fadeInUpBig">
          <li v-for="(item,index) in content2" :key="index">
            <b>{{item.title}}</b>
            <div>
              <span class="span_block">电话：{{item.title3}}</span>
              <span class="span_block" v-if="item.title2">邮箱：{{item.title2}}</span>
            </div>
            <div>
              <span class="span_block span_dress address_li">地址：<span class="add_r">{{item.title4}}</span></span>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="!fieldShow" class="height2r"></div>
  </div>
</template>
<script>
import banner from "../components/home/banner.vue";
export default {
  name: "contactUs",
  components: {
    banner
  },
  data() {
    return {
      showTwo:false,
      fieldShow:false,
      inwidth: window.innerWidth,
      content0:{},
      content1:{},
      content2:[],
      center: { lng: 120.217285, lat: 30.246982 },
      zoom: 33
    };
  },
  mounted() {
    this.onLoad();
    this.contact_us();
    window.addEventListener("scroll", this.onScroll,true);
     if(this.inwidth >480){
      setTimeout((res) => {
      this.fieldShow = true;
    }, 2500);
    }else{
      this.fieldShow = true;
    }
  },
  methods: {
    onScroll(){
				let inHeight = window.innerHeight
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if(scrollTop>0){
        this.fieldShow = true;
      }
      if(this.inwidth >= 1680 && scrollTop > 650){
        console.log('this.showTwo',this.showTwo)
				  this.showTwo = true
				}else if(this.inwidth >= 1560 && this.inwidth < 1680 && scrollTop > 620){
				  this.showTwo = true
				}else if(this.inwidth >= 1400 && this.inwidth < 1560 && scrollTop > 560){
				  this.showTwo = true
				}else if(this.inwidth >= 1200 && this.inwidth < 1400 && scrollTop > 500){
				  this.showTwo = true
				}else if(this.inwidth >= 1024 && this.inwidth < 1200 && scrollTop > 440){
				  this.showTwo = true
				}
		},
    //banner
    contact_us() {
			let that = this;
			let data = {};
			that.$api.contact_us(data).then(res => {
				if (res.status == 1) {
          this.content0 = res.data.content0;
          this.content2 = res.data.content2;
          this.center.lng = res.data.content1.title;
          this.center.lat = res.data.content1.title2;
				} else {
					layer.msg(res.msg);
				}
			});
    },
    //一下是交互
    onLoad() {
      let that = this;
      let data = {};
    },
    onScroll(){
      let that=this;
    },
    handler({ BMap, map }) {
      console.log('map[==');
      var point = new BMap.Point(120.217285, 30.246982)
      var marker = new BMap.Marker(point);  // 创建标注
      map.addOverlay(marker);              // 将标注添加到地图中
      map.centerAndZoom(point, 33);
      var opts = {
        width : 300,     // 信息窗口宽度
        height: 70,     // 信息窗口高度
        title : "杭实国贸（杭州）投资有限公司" , // 信息窗口标题
        enableMessage:false,//设置允许信息窗发送短息
      }
      var infoWindow = new BMap.InfoWindow("地址：中国浙江杭州钱江新城香樟街2号泛海国际中心a座32层", opts);  // 创建信息窗口对象 
      marker.addEventListener("click", function(){          
        map.openInfoWindow(infoWindow,point); //开启信息窗口
      });
    },
    getClickInfo(e) {
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    },
  },
};
</script>

<style >
.lxwm_box {
  width: 100%;
  height: 5.8rem;
  margin-top: .5rem;
}
.lxwm_dt {
    height: 5.8rem;
    font-size: 14px;
}
.cont_one{
  position: relative;
}
.cont_ct{
  width: 30%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 99;
  box-shadow: rgba(53, 53, 53, 0.1) 0px 0px 20px;
  padding: .4rem .3rem;
}
.cont_ul{
  font-size: .16rem;
}
.cont_ct_txt{
  font-size: .24rem;
  color: #333;
  padding-bottom: .3rem;
}
.cont_li{
  display: flex;
  justify-content: flex-start;
  font-size: .16rem;
  color: #4c4c4c;
  padding: .3rem 0;
  border-top: 1px solid #e6e6e6;
}
.cont_li img{
  width: .28rem;
  height: .28rem;
  margin-right: .3rem;
}
.cont_li .cont_ny span{
   display: block;
   font-size: .18rem;
   margin-bottom: .05rem;
}
.cont_ewm{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: .2rem;
}
.cont_ewm img{
  display: block;
  width: 1.1rem;
  height: 1.1rem;
  margin-right: .1rem;
}
.cont_li .cont_ny .cont_ewm span{
  display: block;
  width: .24rem;
  font-size: .13rem;
  line-height: 100%;
}
.tel_box{
  margin-top: .6rem;
  padding-bottom: 1.4rem;
}
.tel_ul{
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -.2rem;
  margin-right: -.3rem;
}
.tel_ul li{
    width: calc(33% - .9rem);
    margin:.2rem;
    border: 1px solid #e0e0e0;
    padding:.2rem .25rem;
    transition: .3s ease-in-out;
    padding-bottom: 0;
  }
.tel_ul b{
      font-weight: normal;
      display: block;
      font-size: .18rem;
      color: #333;
      margin-bottom: .1rem;
  }
.tel_ul div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.tel_ul div span{
  margin-left: .1rem;
  margin-right: .2rem;
}
.tel_ul div span:last-child{
margin-right: 0;
}
.tel_ul div span:first-child{
  margin-left: 0;
}
.tel_ul li:hover{
  box-shadow: 0px .1rem .2rem #e0e0e0;
}
.span_block{
  display: block;
  margin-left: 0!important;
  line-height: .24rem;
  font-size: .14rem;
}
.tel_ul div .span_dress{
  height: .48rem;
  margin-right: 0;
}
@media (max-width: 1680px) {
  .cont_li{
    padding: .25rem 0;
  }
  .tel_ul div{
    min-height: .5rem;
  }
  /* .tel_ul li{
    padding: .2rem .15rem;
  } */
  .tel_ul li {
    width: calc(33% - .9rem);
  }

}
@media (max-width: 1560px) {
  .tel_ul li {
      width: calc(33% - .7rem);
      margin: .1rem;
      border: 1px solid #e0e0e0;
      padding: .2rem .25rem;
      transition: .3s ease-in-out;
      cursor: pointer;
      padding-bottom: 0;
  }
  .cont_li{
    padding: .2rem 0;
  }
}
@media (max-width: 1400px) {
  .tel_ul li {
      width: calc(33% - .7rem);
      margin: .1rem;
      transition: .3s ease-in-out;
  }
  .cont_ct_txt{
    font-size: .2rem;
  }
  .tel_ul div{
    height: .5rem;
  }
}
@media (max-width: 1024px) {
  .tel_ul li {
    width: calc(50% - .8rem);
    padding: .2rem .25rem;
    padding-bottom: 0;
  }
  .cont_ct{
    width: 35%;
  }
}
@media (max-width: 480px) {
.cont_ct{
  position: relative;
  width: auto;
  box-shadow:none;
  padding: 0;
}
.cont_ct_txt{
  font-size: 18px;
}
.cont_li .cont_ny{
  font-size: 14px;
}
.cont_li .cont_ny span{
  font-size: 14px;
}
.cont_li img{
  width: 22px;
  height: 22px;
}
.cont_ewm img{
  width: 100px;
  height: 100px;
}
.cont_li .cont_ny .cont_ewm span{
  font-size: 12px;
}
.tel_ul{
  display: block;
  margin-left: 0; 
  margin-right: 0;
}
.tel_ul li{
  width: calc(100% - .55rem);
  margin: 0;
  margin-bottom: .3rem;
}
.tel_ul b{
  font-size: 16px;
}
.span_block{
  font-size: 12px;
}
}

@media (max-width: 320px) {
.tel_ul div{
  height: auto;
}
.tel_ul li{
  padding-bottom: .2rem;
}
.tel_ul div span{
  display: block;
  line-height: .54rem;
}
}

.address_li{
  display: flex;
  align-items: flex-start;
}
.add_r{
  flex: 1;
}

</style>
